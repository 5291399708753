<template>
  <div class="box">
    <topMenu :index="2"></topMenu>
    <div class="content">
      <div class="list">
        <div
          class="list-item"
          v-for="(item, index) in list"
          :key="index"
          @click="toDetails(item.id)"
        >
          <div class="dot"></div>
          <div>{{ item.title }}</div>
          <div>({{ item.openDate }})</div>
        </div>
      </div>
      <el-pagination
        class="fixed"
        background
        layout="total,prev, pager, next"
        :total="total"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import config from "../config/config";
import topMenu from "@/components/topMenu.vue";
import Footer from "../components/Footer.vue";
import { contentList } from "../api/index";
export default {
  components: {
    topMenu,
    Footer,
  },
  data() {
    return {
      config,
      state: null,
      content: "",
      list: [],
      param: {
        pageNum: 1,
        pageSize: 14,
        section: "",
      },
      total: 0,
    };
  },
  methods: {
    // 列表
    getList(data) {
      contentList(data).then((res) => {
        // console.log("res", res);

        res.rows.forEach((item, index, arr) => {
          if (item.openDate) {
            arr[index].openDate =
              item.openDate.split("-")[0] +
              "年" +
              item.openDate.split("-")[1] +
              "月" +
              item.openDate.split("-")[2] +
              "日";
          }
        });
        this.list = res.rows;
        this.total = res.total;
        // this.subtitle = res.data.news;
      });
    },
    // 详情
    toDetails(id) {
      this.$router.push("/news?section=" + id);
    },
    // 分页
    handleCurrentChange(e) {
      this.param.pageNum = e;
      this.getList(this.param);
      // console.log("xeee", e);
    },
  },
  mounted() {
    // console.log("eeee", this.$route.query);
    if (this.$route.query) {
      this.param.section = this.$route.query.section;
      this.getList(this.param);
    }
  },
};
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  position: relative;
  margin: 1.85vh 18.75vw 4.63vh;
  min-height: 74.07vh;
  .fixed {
    position: absolute;
    bottom: 0;
  }
  .list {
    .list-item {
      display: flex;
      font-size: 1.25vw;
      align-items: center;
      margin-bottom: 2.4vh;
      &:hover {
        cursor: pointer;
        color: #085ed6;
      }
      .dot {
        width: 8px;
        height: 8px;
        background: #085dd5;
        opacity: 0.5;
        margin-right: 1vw;
      }
    }
  }
}
</style>
